//Moves
export const toolboxMove = {
    left($el){
        $el.insertBefore($el.prevAll('li').first());
        $el.focus();
    },
    right($el){
        $el.insertAfter($el.nextAll('li').first());
        $el.focus();
    },
    toggle($el){
        //Clean up any popovers and tooltips first
        if (window.innerWidth > 1200) {
            $el.parents('.toolbox').find('[data-toggle="tooltip"]').tooltip('hide');
        } else {
            $('.info').popover('hide'); 
        }

        if ($el.parents('.toolbox__remaining ul').length > 0) {
            $el.parents('.toolbox').children('ul').append($el);
            
            if (window.innerWidth > 1200) {
                $el.focus();
            }
        } else {            
            $el.parents('.toolbox').find('.toolbox__remaining ul').append($el);
            if ($el.parents('.toolbox').hasClass('-customising') && window.innerWidth > 1200) {
                $el.focus();
            }
        }
    }
}